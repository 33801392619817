@use "styles/variables/colors/colors";

.appdev {
  padding: 80px 0;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    padding: 0 15px;
    margin: 0 auto;
  }

  &__header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.625rem;
    line-height: 2.75rem;
    color: colors.$defaultTextColor;
    margin-bottom: 55px;
  }

  &__subheader {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.75rem;
    color: colors.$subheaderColor;
    margin-bottom: 25px;
  }

  &__content {
    max-width: 540px;
    margin-right: 25px;

    &-label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 80px;
      color: colors.$iconSuccess;
      margin-bottom: 15px;
    }

    &-header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2rem;
      color: colors.$defaultTextColor;
      margin-bottom: 15px;
    }

    &-subheader {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: colors.$subheaderColor;
      margin-bottom: 20px;
    }

    &-footer {
      display: flex;
      align-items: center;
      margin-bottom: 55px;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    margin-right: 30px;

    &-text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      text-transform: uppercase;
      color: colors.$defaultTextColor;
    }

    &-value {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      color: colors.$defaultTextColor;
    }

  }

  &__review {
    &-text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
      text-transform: uppercase;
      color: colors.$defaultTextColor;
    }
  }

  &__img-wrapper {
    max-width: 445px;
    width: 100%;
    margin-right: 30px;
  }

  &__img--mobile {
    display: none;
    border-radius: 40px;
    overflow: hidden;
  }
}

.star-wrapper {
  margin: 0 5px 0 15px;
}

@media screen and (max-width: 940px) {
  .appdev {
    &__container {
      flex-direction: column;
    }

    &__content {
      margin: 0 0 25px 0;
    }

    &__img-wrapper {
      max-width: 540px;
      margin: 0 0 30px 0;
    }

    &__img--desc {
      display: none;
    }

    &__img--mobile {
      display: block;
    }
  }
}

@media screen and (max-width: 540px) {
  .appdev {
    &__header {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    &__subheader {
      font-size: 1.3rem;
      line-height: 1.75rem;
    }

    &__content {
      &-header {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      &-subheader {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
  }
}